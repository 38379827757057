import React, { useState } from "react";
import "./index.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
function Admin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const login = () => {
    axios
      .post("https://api.stthomaschurchjanakpuri.in/api/login", {
        email,
        password,
      })
      .then((data) => {
        if (data.data.success) {
          navigate("/admin/events/teams", {
            replace: true,
            state: {
              loggedIn: true,
            },
          });
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
  return (
    <div class="login-page">
      <div class="form">
        <ToastContainer />
        <div class="login">
          <div class="login-header">
            <h2>LOGIN</h2>
            <p>Please enter your credentials to login.</p>
          </div>
        </div>
        <form
          class="login-form"
          onSubmit={(e) => {
            e.preventDefault();
            login();
          }}
        >
          <input
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            type="text"
            placeholder="email"
          />
          <input
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            type="password"
            placeholder="password"
          />
          <button className="login_btn">login</button>
        </form>
      </div>
    </div>
  );
}

export default Admin;
