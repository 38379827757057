import React from "react";
import BreadCrumb from "../../components/BreadCrumbs";
import mathruvedhi from "../../assets/mathruvedi.jpg";
const MathruVedhi = () => {
  return (
    <>
      <BreadCrumb
        innerRoute="Associations"
        outerRoute="Home"
        title="Mathruvedhi"
      />
      <section class="about_area section_gap">
        <div class="container">
          <div class="row">
            <div class="col-md-6 d_flex">
              <div class="about_content flex">
                <h3 class="title_color">Introduction</h3>
                <p style={{ fontSize: 20, fontWeight: 600, letterSpacing: 2 }}>
                  Mathruvedhi is an organisation for mothers . The organisation
                  has been formed in the parish to bring together all the
                  mothers to actively participate in activities of the Parish
                  aiming life Jesus Christ. Presently all the mothers of the
                  parish are actively involved and varies activities have been
                  carried out under the leadership of Mathruvedhi.
                </p>
              </div>
            </div>
            <div class="col-md-6">
              <img src={mathruvedhi} alt="abou_img" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MathruVedhi;
