import React from "react";
import BreadCrumb from "../../components/BreadCrumbs";

const Gallery = () => {
  return (
    <>
      <BreadCrumb title="Gallery" innerRoute="Gallery" outerRoute="Home" />
      <section class="gallery_area section_gap">
        <div class="container">
          <div class="row imageGallery1" id="gallery">
            <div class="col-md-4 gallery_item">
              <div class="gallery_img">
                <img src={require("../../assets/1st_mass.jpg")} alt="" />
                <div class="hover">
                  <a class="light" href={require("../../assets/1st_mass.jpg")}>
                    <i class="fa fa-expand"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-4 gallery_item">
              <div class="gallery_img">
                <img src={require("../../assets/ribbon_cut.jpg")} alt="" />
                <div class="hover">
                  <a
                    class="light"
                    href={require("../../assets/ribbon_cut.jpg")}
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-4 gallery_item">
              <div class="gallery_img">
                <img src={require("../../assets/Bishops.jpg")} alt="" />
                <div class="hover">
                  <a class="light" href={require("../../assets/Bishops.jpg")}>
                    <i class="fa fa-expand"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-4 gallery_item">
              <div class="gallery_img">
                <img src={require("../../assets/St_thomas.jpg")} alt="" />
                <div class="hover">
                  <a class="light" href={require("../../assets/St_thomas.jpg")}>
                    <i class="fa fa-expand"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-4 gallery_item">
              <div class="gallery_img">
                <img src={require("../../assets/Joseph_Marry.jpg")} alt="" />
                <div class="hover">
                  <a class="light" href="image/gallery/05.jpg">
                    <i class="fa fa-expand"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-4 gallery_item">
              <div class="gallery_img">
                <img src={require("../../assets/stone_blessing.jpg")} alt="" />
                <div class="hover">
                  <a
                    class="light"
                    href={require("../../assets/stone_blessing.jpg")}
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Gallery;
