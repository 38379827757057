const catechismTeachers = [
  {
    teacher: "Brigette George",
    class: "KG",
    countOfStudents: "9",
  },
  {
    teacher: "Rosmy Davis",
    class: "I",
    countOfStudents: "21",
  },
  {
    teacher: "Teby Tom",
    class: "II",
    countOfStudents: "16",
  },
  {
    teacher: "Rev. Sr. Teslit SABS",
    class: "III",
    countOfStudents: "11",
  },
  {
    teacher: "Riya Davis",
    class: "IV - A",
    countOfStudents: "12",
  },
  {
    teacher: "Rev. Sr. Vinaya CHF",
    class: "IV - B",
    countOfStudents: "10",
  },
  {
    teacher: "Rev. Sr. Elsa SABS",
    class: "V",
    countOfStudents: "13",
  },
  {
    teacher: "Mrs. Molly Mathew",
    class: "VI",
    countOfStudents: "6",
  },
  {
    teacher: "Mr. Jobin Chacko",
    class: "VII",
    countOfStudents: "8",
  },
  {
    teacher: "Mrs. Reena Jose",
    class: "VIII",
    countOfStudents: "8",
  },
  {
    teacher: "Rev. Sr. Jomy CHF",
    class: "IX",
    countOfStudents: "7",
  },
  {
    teacher: "Mrs. Veena Shaju",
    class: "X",
    countOfStudents: "5",
  },
  {
    teacher: "Mrs. Annie George",
    class: "XI",
    countOfStudents: "4",
  },
  {
    teacher: "Mr. Tojo Jose",
    class: "XII",
    countOfStudents: "7",
  },
  {
    teacher: "Rev. Sr. Amal Rose SABS",
    class: "Counselling, Altar Boys",
    countOfStudents: "-",
  },
  {
    teacher: "Mr. Augustine Peter, IES",
    class: "Mentor",
    countOfStudents: "-",
  },
  {
    teacher: "Mr. Jiffin Joseph",
    class: "Choir",
    countOfStudents: "-",
  },
  {
    teacher: "Mr.Vipin Joseph",
    class: "HM",
    countOfStudents: "-",
  },
  {
    teacher: "Thomas Sebastian",
    class: "President - Nurses Guild",
    countOfStudents: "-",
  },
];

export default catechismTeachers;
