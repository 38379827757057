import { Paper } from "@mui/material";
import { Box, Container } from "@mui/system";
import React, { useState } from "react";
import BreadCrumb from "../../components/BreadCrumbs";
import Pagination from "../../components/Pagination";
import catechismTeachers from "../../data/Catechism";

const Catechism = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(6);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  const nPages = Math.ceil(catechismTeachers.length / recordsPerPage);

  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);
  return (
    <>
      <BreadCrumb
        innerRoute="Catechism"
        outerRoute="Home"
        title="Associations"
      />

      <section className="about_area section_gap">
        <div className="container">
          <div className="row">
            <div className="text-center">
              <div className="about_content">
                <h3 className="title_color">Introduction</h3>
                <p style={{ fontSize: 20, fontWeight: 600, letterSpacing: 2 }}>
                  The Catechism Department is one of the important pillars of
                  St. Thomas parish, Janakpuri. The team is presently working
                  under the dynamic leadership of Parish priest Rev. Fr. Shijo
                  Ottaplackal. Presently, 137 students are studying in 14
                  divisions, and 18 teachers are doing their ministry to this
                  department. The catechism starts with Holy Mass at 8.30 AM
                  followed by an hour of catechism classes on all Sundays.
                </p>
              </div>
            </div>
            <div className="col-md-6"></div>
          </div>
        </div>
      </section>

      <Container>
        <div className="section-top-border">
          <h3 className="mb-30 title_color">Information Board.</h3>
          <Paper elevation={3}>
            <div className="progress-table-wrap">
              <div className="progress-table">
                <div className="table-head">
                  <div className="serial">No.</div>
                  <div className="country">Name of the Teacher</div>
                  <div className="visit">Class</div>
                  <div className="percentage">Number of Students.</div>
                </div>
                {catechismTeachers
                  .slice(indexOfFirstRecord, indexOfLastRecord)
                  .map((res, index) => (
                    <div className="table-row">
                      <div className="serial">
                        <h6 className="typo-list">{index + 1}</h6>
                      </div>
                      <div className="country">
                        <h6 className="typo-list">{res.teacher}</h6>
                      </div>
                      <div className="visit">
                        <h6 className="typo-list">{res.class}</h6>
                      </div>
                      <div className="percentage">
                        <h6 className="typo-list text-center">
                          {res.countOfStudents}
                        </h6>
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            <Box>
              <Pagination
                currentPage={currentPage}
                nPages={pageNumbers}
                setCurrentPage={setCurrentPage}
              />
            </Box>
          </Paper>
        </div>
        <Box>
          <h5 className="typo-list" style={{ color: "red", cursor: "pointer" }}>
            - Previous Year Question Paper
          </h5>
        </Box>
      </Container>
    </>
  );
};

export default Catechism;
