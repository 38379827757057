import React from "react";

const Hero = () => {
  return (
    <section className="banner_area d-flex text-center">
      {/* eslint-disable */}
      <div className="container align-self-center">
        <div className="row">
          <div className="col-md-12">
            <div className="banner_content">
              <h1>
                St.Thomas Syro <br /> Malabar Catholic Church
              </h1>
              <p>
                St. Thomas Syro-Malabar Catholic Church, Janakpuri, New Delhi is
                a parish under the Diocese of Faridabad, New Delhi.
                <br />
                Presently, more than 170 families are members of this Parish.
                <br />
              </p>
              <a href="#" className="btn_hover btn_hover_two">
                Get Started
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
