import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";

const Header = ({ path }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <header className="header_area">
      {/* eslint-disable */}
      <div class="header_top">
        <div class="container">
          <div class="row"></div>
        </div>
      </div>
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container">
          <a className="navbar-brand logo_h">
            <img src={require("../../assets/favicon.png")} alt="" width={90} />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>

          <div
            className="collapse navbar-collapse offset"
            id="navbarSupportedContent"
          >
            <ul className="nav navbar-nav menu_nav ml-auto">
              <li className={pathname == "/" ? `nav-item active` : "nav-item"}>
                <a
                  className="nav-link"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Home
                </a>
              </li>
              <li
                className={`nav-item submenu dropdown ${
                  pathname.includes("/associations") ? "active" : ""
                } `}
              >
                <a
                  className="nav-link dropdown-toggle"
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Associations
                </a>
                <ul className="dropdown-menu">
                  <li className="nav-item">
                    <a
                      className={"nav-link"}
                      onClick={() => {
                        navigate("/associations/parish-council");
                      }}
                    >
                      Parish Council
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      onClick={() => {
                        navigate("/associations/catechism");
                      }}
                    >
                      Catechism
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      onClick={() => navigate("/associations/mathruvedhi")}
                    >
                      Mathruvedhi
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      onClick={() => navigate("/associations/dsym")}
                    >
                      DSYM
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      onClick={() => navigate("/associations/nurses-guild")}
                    >
                      Nurses Guild
                    </a>
                  </li>
                  {/* <li className="nav-item">
                    <a
                      className="nav-link"
                      onClick={() => navigate("/associations/choir")}
                    >
                      Choir
                    </a>
                  </li> */}
                </ul>
              </li>
              <li
                onClick={() => {
                  navigate("/events");
                }}
                className={`nav-item ${
                  pathname.includes("/event") ? "active" : ""
                }`}
              >
                <a className="nav-link">Events</a>
              </li>
              <li className="nav-item">
                <a className="nav-link">Spiritual Growth</a>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link" href="sermons.html">
                  Faith Formation
                </a>
              </li> */}

              <li className="nav-item submenu dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                  href="ministries.html"
                >
                  General
                </a>
                <ul className="dropdown-menu">
                  <li className="nav-item">
                    <a className="nav-link">Join The Parish</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link">Donation To Church</a>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a className="nav-link" onClick={() => navigate("/gallery")}>
                  Gallery
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" onClick={() => navigate("/contact")}>
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
