import React from "react";
import BreadCrumb from "../../components/BreadCrumbs";

const Choir = () => {
  return (
    <>
      <BreadCrumb innerRoute="Associations" outerRoute="Home" title="Choir" />
    </>
  );
};

export default Choir;
