import React from "react";

const Footer = () => {
  return (
    <footer class="footer-area section_gap">
      {/* eslint-disable */}
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="single-footer-widget">
              <h6 class="footer_title">About Church</h6>
              <p>
                St. Thomas Syro-Malabar Catholic Church, Janakpuri, New Delhi is
                a parish under the Diocese of Faridabad, New Delhi. Presently,
                more than 170 families are members of this Parish.
              </p>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="single-footer-widget">
              <h6 class="footer_title">Navigation Links</h6>
              <div class="row">
                <div class="col-5">
                  <ul class="list_style">
                    <li>
                      <a href="#">Home</a>
                    </li>
                    <li>
                      <a href="#">Associations</a>
                    </li>
                    <li>
                      <a href="/associations/dsym">DSYM</a>
                    </li>
                    <li>
                      <a href="/associations/mathruvedhi">Mathruvedhi</a>
                    </li>
                  </ul>
                </div>
                <div class="col-5">
                  <ul class="list_style">
                    <li>
                      <a href="#">General</a>
                    </li>
                    <li>
                      <a href="/contact">Contact</a>
                    </li>
                    <li>
                      <a href="/associations/catechism">Catechism</a>
                    </li>
                    <li>
                      <a href="/associations/parish-council">Parish Council</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="border_line"></div>
        <div class="row footer-bottom d-flex justify-content-between align-items-center">
          <p class="col-lg-8 col-sm-8 footer-text m-0">
            Copyright &copy;
            {new Date().getFullYear()}, All rights reserved
          </p>
          <div class="col-lg-4 col-sm-4 footer-social">
            <a target="_blank" href="https://www.instagram.com/dsymjanakpuri/">
              <i class="fa-brands fa-instagram"></i>
            </a>
            <a
              target="_blank"
              href="https://api.whatsapp.com/send/?phone=%2B919310023605&text&type=phone_number&app_absent=0"
            >
              <i class="fa-brands fa-whatsapp"></i>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
