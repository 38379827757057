const parishCouncilMembers = [
  { name: "Fr. Shijo Ottaplackal, VC", designation: "Vicar", unitName: "-" },
  {
    name: "Mr. Mathew M K",
    designation: "Kaikkaran",
    unitName: "Infant Jesus",
  },
  {
    name: "Mr. Tojo Jose",
    designation: "Kaikkaran",
    unitName: "Sacred Heart",
  },
  {
    name: "Mrs. Mary Peter",
    designation: "President",
    unitName: "Sacred Heart",
  },
  {
    name: "Mr. Jimshy  Davis",
    designation: "President",
    unitName: "St. Thomas Unit",
  },
  {
    name: "Mrs. Kochurani Wilson",
    designation: "President",
    unitName: "St. Mary's Unit",
  },
  {
    name: "Mrs. Anila Siji",
    designation: "President",
    unitName: "Holy family Unit",
  },
  {
    name: "Rev. Sr. Elsa, SABS",
    designation: "Mother Superior",
    unitName: "SABS Janakpuri",
  },
  {
    name: "Rev. Sr. Jomy, CHF",
    designation: "Mother Superior",
    unitName: "CHF, Janakpuri",
  },
  {
    name: "Mr. K K George",
    designation: "Member",
    unitName: "Pastoral Council",
  },
  {
    name: "Mr. Vipin Joseph",
    designation: "H.M",
    unitName: "Catechism",
  },
  {
    name: "Mr. Jiffin Joseph",
    designation: "President",
    unitName: "DSYM",
  },
];

export default parishCouncilMembers;
