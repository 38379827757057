import { Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import routes from "./routes";
import Admin from "./Pages/Admin";
import List from "./Pages/Admin/List";

function App() {
  return (
    <Routes>
      {routes.map(({ path, Component }) => (
        <Route
          path={path}
          element={
            <>
              <Header path={path} />
              <Component />
              <Footer />
            </>
          }
        />
      ))}

      <Route path="/admin" element={<Admin />} />
      <Route path="/admin/events/teams" element={<List />} />
    </Routes>
  );
}

export default App;
