import { Container, Paper } from "@mui/material";
import React, { useState } from "react";
import BreadCrumb from "../../components/BreadCrumbs";
import dsym from "../../assets/DSYM.jpg";
import { Box } from "@mui/system";
import DsymCouncil from "../../data/DSYM";
import Pagination from "../../components/Pagination";

const Dsym = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(6);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  const nPages = Math.ceil(DsymCouncil.length / recordsPerPage);

  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);
  return (
    <>
      <BreadCrumb innerRoute="Associations" outerRoute="Home" title="DSYM" />
      <Container>
        <Box className="section-top-border">
          <h3 style={{ margin: 10 }} class="title_color">
            - DSYM Council
          </h3>

          <img style={{ width: "100%" }} src={dsym} alt="" />
        </Box>
        <h3 style={{ margin: 10 }} class="title_color">
          - DSYM Council
        </h3>
        <Paper sx={{ m: 2 }} elevation={3}>
          <div class="progress-table-wrap">
            <div class="progress-table">
              <div class="table-head">
                <div class="serial">No.</div>
                <div class="country">Name</div>
                <div class="visit">Designation</div>
              </div>
              {DsymCouncil.slice(indexOfFirstRecord, indexOfLastRecord).map(
                (res, index) => (
                  <div class="table-row">
                    <div class="serial">
                      <h6 className="typo-list">{index + 1}</h6>
                    </div>
                    <div class="country">
                      <h6 className="typo-list">{res.name}</h6>
                    </div>
                    <div class="visit">
                      <h6 className="typo-list">{res.designation}</h6>
                    </div>
                    <div class="percentage">
                      <h6 className="typo-list text-center">{res.unitName}</h6>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>

          <Box>
            <Pagination
              currentPage={currentPage}
              nPages={pageNumbers}
              setCurrentPage={setCurrentPage}
            />
          </Box>
        </Paper>
      </Container>
    </>
  );
};

export default Dsym;
