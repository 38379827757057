import React from "react";
import BreadCrumb from "../../components/BreadCrumbs";

const NursesGuild = () => {
  return (
    <>
      <BreadCrumb
        innerRoute="Associations"
        outerRoute="Home"
        title="Nurses Guild"
      />
      <section class="about_area section_gap">
        <div class="container">
          <div class="row">
            <div class="col-md-12 d_flex">
              <div class="about_content flex">
                <h3 class="title_color">Introduction</h3>
                <p style={{ fontSize: 20, fontWeight: 600, letterSpacing: 2 }}>
                  Nurses guild is an association of laity nurses working in
                  different hospitals in the area of Faridabad Diocese. Nurses
                  guild is playing an active role in supporting health care of
                  the parish members and also in giving mutual support to the
                  Nurses. Nurses Guild at St. Thomas Church, Janakpuri was
                  inaugurated on 14.03.2021 by Rev. Fr. Joyson Puthussery, then
                  Vicar. Presently more than 80 nurses in the parish are members
                  of Nurses Guild.
                </p>
              </div>
            </div>
            <div class="col-md-6">
              {/* <img src={mathruvedhi} alt="abou_img" /> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NursesGuild;
