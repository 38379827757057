import { Box } from "@mui/system";
import React from "react";
import BreadCrumb from "../../components/BreadCrumbs";

const JoinOurParish = () => {
  return (
    <>
      <BreadCrumb
        innerRoute="General"
        outerRoute="Home"
        title="Join Our Parish"
      />
      <Box>
        <div class="col-lg-8 col-md-8">
          <h3 class="mb-30 title_color">Form Element</h3>
          <form action="#">
            <div class="mt-10">
              <input
                type="text"
                name="first_name"
                placeholder="First Name"
                required=""
                class="single-input"
              />
            </div>
            <div class="mt-10">
              <input
                type="text"
                name="last_name"
                placeholder="Last Name"
                required=""
                class="single-input"
              />
            </div>
            <div class="mt-10">
              <input
                type="text"
                name="last_name"
                placeholder="Last Name"
                required=""
                class="single-input"
              />
            </div>
            <div class="mt-10">
              <input
                type="email"
                name="EMAIL"
                placeholder="Email address"
                required=""
                class="single-input"
              />
            </div>
            <div class="input-group-icon mt-10">
              <div class="icon">
                <i class="fa fa-thumb-tack" aria-hidden="true"></i>
              </div>
              <input
                type="text"
                name="address"
                placeholder="Address"
                required=""
                class="single-input"
              />
            </div>
            <div class="input-group-icon mt-10">
              <div class="icon">
                <i class="fa fa-plane" aria-hidden="true"></i>
              </div>
              <div class="form-select" id="default-select">
                <select style={{ display: "none" }}>
                  <option value="1">City</option>
                  <option value="1">Dhaka</option>
                  <option value="1">Dilli</option>
                  <option value="1">Newyork</option>
                  <option value="1">Islamabad</option>
                </select>
                <div class="nice-select" tabindex="0">
                  <span class="current">City</span>
                  <ul class="list">
                    <li data-value="1" class="option selected">
                      City
                    </li>
                    <li data-value="1" class="option">
                      Dhaka
                    </li>
                    <li data-value="1" class="option">
                      Dilli
                    </li>
                    <li data-value="1" class="option">
                      Newyork
                    </li>
                    <li data-value="1" class="option">
                      Islamabad
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="input-group-icon mt-10">
              <div class="icon">
                <i class="fa fa-globe" aria-hidden="true"></i>
              </div>
              <div class="form-select" id="default-select2">
                <select style={{ display: "none" }}>
                  <option value="1">Country</option>
                  <option value="1">Bangladesh</option>
                  <option value="1">India</option>
                  <option value="1">England</option>
                  <option value="1">Srilanka</option>
                </select>
                <div class="nice-select" tabindex="0">
                  <span class="current">Country</span>
                  <ul class="list">
                    <li data-value="1" class="option selected">
                      Country
                    </li>
                    <li data-value="1" class="option">
                      Bangladesh
                    </li>
                    <li data-value="1" class="option">
                      India
                    </li>
                    <li data-value="1" class="option">
                      England
                    </li>
                    <li data-value="1" class="option">
                      Srilanka
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="mt-10">
              <textarea
                class="single-textarea"
                placeholder="Message"
                required=""
              ></textarea>
            </div>
            <div class="mt-10">
              <input
                type="text"
                name="first_name"
                placeholder="Primary color"
                required=""
                class="single-input-primary"
              />
            </div>
            <div class="mt-10 /">
              <input
                type="text"
                name="first_name"
                placeholder="Accent color"
                required=""
                class="single-input-accent "
              />
            </div>
            <div class="mt-10 /">
              <input
                type="text"
                name="first_name"
                placeholder="Secondary color"
                required=""
                class="single-input-secondary"
              />
            </div>
          </form>
        </div>
      </Box>
    </>
  );
};

export default JoinOurParish;
