import React from "react";

const BreadCrumb = ({ title, innerRoute, outerRoute, image }) => {
  const styles = image && {
    background:
      "url(https://lh6.googleusercontent.com/ck7hbsVGe29FnTJzPEAqA-TKt4FUKIrzUZexto77JLDxRuO7xrDJNd8GGUIM35EwkMAJkfbivN8bJ_Ss_17X05S8pHbd1pTP08L6P4kI1hmpQrparS89cWRRdnUjf90JCA=w1126)",
    backgroundPosition: "center center",
    height: 290,
  };
  return (
    <section
      className={`breadcrumb_area ${image ? null : "br_image"}`}
      style={{
        ...styles,
      }}
    >
      {/* eslint-disable */}
      <div className="container">
        <div className="page-cover text-center">
          <h2
            className="page-cover-tittle"
            style={{
              color: image ? "#FC2E2F" : null,
            }}
          >
            {title}
          </h2>
          {outerRoute && (
            <ol className="breadcrumb">
              <li>
                <a>{outerRoute}</a>
              </li>
              <li className="active">{innerRoute}</li>
            </ol>
          )}
        </div>
      </div>
    </section>
  );
};

export default BreadCrumb;
