import { Container } from "@mui/system";
import React from "react";
import Hero from "../../components/Hero";

const Home = () => {
  return (
    <>
      <Hero />
      <section class="event_blog_area section_gap">
        <h3 class="title_color text-center mt-3">Our Bishop's</h3>

        <div class="container">
          <div class="row ministries_info">
            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="event_post">
                <img src={require("../../assets/Pope.jpg")} alt="" />
                <a href="#">
                  <h2 style={{ fontSize: 17 }} class="event_title text-center">
                    His Holiness Pope Francis
                  </h2>
                </a>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="event_post">
                <img
                  src={require("../../assets/george_alanchery.jpg")}
                  alt=""
                />
                <a href="#">
                  <h2 style={{ fontSize: 17 }} class="event_title text-center">
                    His Beatitude Cardinal Mar George Alancherry
                  </h2>
                </a>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="event_post">
                <img
                  src={require("../../assets/kuriyakose_bharanikulangra.jpeg")}
                  alt=""
                />
                <a href="#">
                  <h2 style={{ fontSize: 17 }} class="event_title text-center">
                    His Excellency Archbishop Kuriakose Bharanikulangara
                  </h2>
                </a>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="event_post">
                <img
                  src={require("../../assets/Jose Puthenveetil.jpg")}
                  alt=""
                  height="100%"
                />
                <h2 style={{ fontSize: 17 }} class="event_title text-center">
                  Mar Jose Puthenveetil, Auxilary Bishop
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* eslint-disable */}
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.675687023184!2d77.09065451495927!3d28.60950458242687!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1b52ec05ae33%3A0xed10f4d9a2a860e4!2sSt.%20Thomas%20Syro-Malabar%20Catholic%20Church!5e0!3m2!1sen!2sin!4v1674465940161!5m2!1sen!2sin"
        width="100%"
        height="300"
        style={{ border: 0 }}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </>
  );
};

export default Home;
