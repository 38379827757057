import ParishCouncil from "../Pages/Associations/ParishCouncil";
import Catechism from "../Pages/Catechism";
import Choir from "../Pages/Choir";
import Contact from "../Pages/Contact";
import Dsym from "../Pages/DSYM";
import Events from "../Pages/Events";
import AdamsCup from "../Pages/Events/AdamsCup";
import Gallery from "../Pages/Gallery";
import Home from "../Pages/Home";
import JoinOurParish from "../Pages/JoinOurParish";
import MathruVedhi from "../Pages/Mathruvedi";
import NursesGuild from "../Pages/NursesGuild";

const routes = [
  {
    path: "/",
    Component: Home,
  },
  {
    path: "/associations/parish-council",
    Component: ParishCouncil,
  },
  {
    path: "/associations/catechism",
    Component: Catechism,
  },

  {
    path: "/associations/mathruvedhi",
    Component: MathruVedhi,
  },

  {
    path: "/associations/dsym",
    Component: Dsym,
  },
  {
    path: "/associations/nurses-guild",
    Component: NursesGuild,
  },
  {
    path: "/associations/choir",
    Component: Choir,
  },
  {
    path: "/general/join-our-parish",
    Component: JoinOurParish,
  },
  {
    path: "/contact",
    Component: Contact,
  },
  {
    path: "/gallery",
    Component: Gallery,
  },
  {
    path: "/events",
    Component: Events,
  },
  {
    path: "/event/adams-cup-2.0",
    Component: AdamsCup,
  },
];

export default routes;
