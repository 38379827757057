import * as yup from "yup";

const formSchema = yup.object({
  team_name: yup
    .string("Please Enter Team Name.")
    .required("Team Name is Required."),
  captain_name: yup
    .string("Please Enter Captain's Name.")
    .required("Captain's Name is required."),
  upi_ref: yup.string("Please Enter UPI Ref.").required("UPI Ref is required."),
  contact_number: yup
    .string("Please Enter Contact's Number.")
    .required("Contact's Number is required."),
  denomination: yup
    .string("Please Enter Denomination.")
    .required("Denomination is required."),
  email: yup
    .string("Please Enter the email.")
    .email("Please Type Proper Email.")
    .required("Email is required."),
  parish_name: yup
    .string("Please Enter Parish Name")
    .required("Parish Name is required."),
  pincode: yup
    .string("Please Enter the Pincode")
    .required("Pincode is required."),
});

export default formSchema;
