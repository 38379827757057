import React from "react";
import BreadCrumb from "../../components/BreadCrumbs";

const Contact = () => {
  return (
    <>
      <BreadCrumb innerRoute="Contact" title="Contact" outerRoute="Home" />
      <section class="contact_area section_gap">
        <div class="container">
          <div id="mapBox" class="mapBox">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.675687023184!2d77.09065451495927!3d28.60950458242687!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1b52ec05ae33%3A0xed10f4d9a2a860e4!2sSt.%20Thomas%20Syro-Malabar%20Catholic%20Church!5e0!3m2!1sen!2sin!4v1674465940161!5m2!1sen!2sin"
              width="100%"
              height="300"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div class="row">
            <div class="col-md-3">
              <div class="contact_info">
                <div class="info_item">
                  <i class="lnr lnr-home"></i>
                  <h6>D-II/15A, Vashisht Park</h6>
                  <p> Janakpuri, New Delhi-45</p>
                </div>
                <div class="info_item">
                  <i class="lnr lnr-phone-handset"></i>
                  <h6>
                    <a href="#">+91-9310023605</a>
                  </h6>
                  <p>Mon to Fri 9am to 6 pm</p>
                </div>
                {/* <div class="info_item">
                  <i class="lnr lnr-envelope"></i>
                  <h6>
                    <a href="#">
                      <span
                        class="__cf_email__"
                        data-cfemail="e695939696899492a685898a89948a8f84c885898b"
                      >
                        [email&#160;protected]
                      </span>
                    </a>
                  </h6>
                  <p>Send us your query anytime!</p>
                </div> */}
              </div>
            </div>
            <div class="col-md-9">
              <form
                class="row contact_form"
                // action="https://preview.colorlib.com/theme/faith/contact_process.php"
                method="post"
                id="contactForm"
                novalidate="novalidate"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <div class="col-md-6">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      id="name"
                      name="name"
                      placeholder="Enter your name"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="email"
                      class="form-control"
                      id="email"
                      name="email"
                      placeholder="Enter email address"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      id="subject"
                      name="subject"
                      placeholder="Enter Subject"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <textarea
                      class="form-control"
                      name="message"
                      id="message"
                      rows="1"
                      placeholder="Enter Message"
                    ></textarea>
                  </div>
                </div>
                <div class="col-md-12 text-right">
                  <button
                    type="submit"
                    value="submit"
                    class="btn btn_hover btn_hover_two"
                  >
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
