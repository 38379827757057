import React from "react";
import "./index.css";
import { Container } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import BreadCrumb from "../../components/BreadCrumbs";
import { useNavigate } from "react-router-dom";
function Events() {
  const navigate = useNavigate();
  return (
    <>
      <BreadCrumb title="Events" outerRoute="Home" innerRoute="Events" />
      <Container>
        <section
          className="about_area section_gap"
          onClick={() => {
            navigate("/event/adams-cup-2.0");
          }}
        >
          <div class="event-container">
            <h3 class="year">2024</h3>
            <div class="event">
              <div class="event-left">
                <div class="event-date">
                  <div class="date">7</div>
                  <div class="month">April</div>
                </div>
              </div>
              <div class="event-right">
                <h3 class="event-title">Adam's Cup 2.0</h3>

                <div class="event-description">
                  We are organizing a Football Tournament...
                </div>

                <div class="event-timing">
                  <AccessTimeIcon /> 8:30 am
                </div>
              </div>
            </div>
          </div>
        </section>
      </Container>
    </>
  );
}

export default Events;
