import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import $ from "jquery";
import DataTable from "react-data-table-component";
import {
  Button,
  CircularProgress,
  Container,
  IconButton,
  Paper,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import Swal from "sweetalert2";
import { CSVLink } from "react-csv";
import Header from "../../components/Header";
function List() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const { state } = useLocation();

  useEffect(() => {
    axios
      .get("https://api.stthomaschurchjanakpuri.in/api/teams")
      .then((data) => {
        setData(data.data);
        setLoading(false);
      });
  }, []);

  const exportData = data.map((data) => ({
    "Team Name": data.team_name,
    "Captain's Name": data.captain_name,
    "UPI Ref": data.upi_ref,
    "Contact Number": data.contact_number,
    Denomination: data.denomination,
    "Parish Name": data.parish_name,
    Pincode: data.pincode,
    Email: data.email,
  }));

  if (state.loggedIn) {
    return (
      <div>
        <Header />
        <Container
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Paper
            elevation={5}
            style={{
              width: "100%",
            }}
          >
            <DataTable
              subHeader
              subHeaderComponent={
                <>
                  <Button
                    style={{
                      background: "green",
                      color: "#fff",
                      fontWeight: 600,
                    }}
                  >
                    <CSVLink
                      style={{
                        color: "white",
                      }}
                      filename="DSYM_JANAKPURI_TEAM_LIST"
                      data={exportData}
                    >
                      Export CSV
                    </CSVLink>
                  </Button>
                </>
              }
              progressPending={loading}
              progressComponent={
                <div
                  style={{
                    height: "30vh",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: 50,
                    }}
                  >
                    <CircularProgress color="success" />
                  </div>
                </div>
              }
              title="Team Data"
              data={data}
              dense
              responsive
              columns={[
                {
                  name: "Sr No.",
                  selector: (row, index) => index + 1,
                },
                {
                  name: "Team Name",
                  selector: (row, index) => row.team_name,
                },
                {
                  name: "Captain's Name",
                  selector: (row, index) => row.captain_name,
                },
                {
                  name: "UPI Ref",
                  selector: (row, index) => row.upi_ref,
                },
                {
                  name: "Contact Number",
                  selector: (row, index) => row.contact_number,
                },
                {
                  name: "Denomination",
                  selector: (row, index) => row.denomination,
                },
                {
                  name: "Parish Name",
                  selector: (row, index) => row.parish_name,
                },
                {
                  name: "Pincode",
                  selector: (row, index) => row.pincode,
                },
                {
                  name: "Email",
                  selector: (row, index) => row.email,
                },
                {
                  name: "Action",
                  cell: (row) => (
                    <>
                      <IconButton
                        onClick={() => {
                          Swal.fire({
                            icon: "question",
                            title: "Do you want to delete the data?",
                            showCancelButton: true,
                            confirmButtonText: "Delete",
                            denyButtonText: `Don't save`,
                            confirmButtonColor: "red",
                          }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                              axios.delete(
                                "https://api.stthomaschurchjanakpuri.in/api/delete-team/" +
                                  row._id,
                                {}
                              );

                              const h = data.filter((x) => x._id !== row._id);
                              setData(h);
                            } else if (result.isDenied) {
                              Swal.fire("Changes are not saved", "", "info");
                            }
                          });
                        }}
                      >
                        <Delete sx={{ fill: "red" }} />
                      </IconButton>
                    </>
                  ),
                },
              ]}
              pagination
            />
          </Paper>
        </Container>
      </div>
    );
  }
}

export default List;
