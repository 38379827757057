import React from "react";

const Pagination = ({ currentPage, nPages, setCurrentPage }) => {
  const nextPage = () => {
    if (currentPage !== nPages.length) setCurrentPage(currentPage + 1);
  };
  const prevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };
  return (
    <nav className="blog-pagination justify-content-center d-flex">
      {/* eslint-disable */}
      <ul className="pagination">
        <li className="page-item" onClick={prevPage}>
          <a className="page-link" aria-label="Previous">
            <span aria-hidden="true">
              <span className="lnr lnr-chevron-left"></span>
            </span>
          </a>
        </li>
        {nPages?.map((res, key) => (
          <li
            className={res === currentPage ? "page-item active" : "page-item"}
            key={key}
            onClick={() => {
              setCurrentPage(res);
            }}
          >
            <a className="page-link">{res}</a>
          </li>
        ))}

        <li className="page-item" onClick={nextPage}>
          <a className="page-link" aria-label="Next">
            <span aria-hidden="true">
              <span className="lnr lnr-chevron-right"></span>
            </span>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
