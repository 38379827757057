const DsymCouncil = [
  {
    name: "Jiffin Joseph",
    designation: "President",
  },
  {
    name: " Sneha George",
    designation: "Vice President",
  },
  {
    name: " Ardhra Jose",
    designation: "Joint Secretary",
  },
  {
    name: "Riya Davis",
    designation: "Treasurer",
  },
  {
    name: "Ajay Joseph",
    designation: "Male Youth Councillor",
  },
  {
    name: " Ancia P Joseph",
    designation: "Female Youth Councillor",
  },
];

export default DsymCouncil;
